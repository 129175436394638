/* eslint-disable */
import { FC, useState } from 'react';
import { Table } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';

import classes from './TRXTab.module.scss';
import { getRowClassName, trxTabColumns } from './helpers/costants';
import TRXTabFilter from './components/TRXTabFilter/TRXTabFilter';

const TrxTab: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { list, count } = useSelector(
    (state: any) => state.playerTrxSlice.playerTrx.data,
  );
  const { loading } = useSelector(
    (state: any) => state.playerTrxSlice.playerTrx,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <>
      {!list && loading ? (
        <GlobalLoader />
      ) : (
        <div>
          <TRXTabFilter
            currentPage={currentPage}
            id={id}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
          />
          <div className={classes.trxTabBody}>
            <Table
              scroll={{ x: 1500 }} // adjust the x value as needed
              style={{ width: '100%' }}
              size='small'
              dataSource={list}
              columns={trxTabColumns}
              rowClassName={getRowClassName}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: count,
                pageSizeOptions: ['10', '20', '50', '100'],
                showQuickJumper: true,
                showSizeChanger: true,
              }}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TrxTab;
